var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dropdown',{staticClass:"hidden sm:block z-50",attrs:{"open":_vm.dropdownOpen},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var hasFocus = ref.hasFocus;
var isOpen = ref.isOpen;
return [_c('span',{staticClass:"flex items-center text-sm rounded-lg px-3 py-1",class:[isOpen ? 'bg-gray-300' : 'bg-gray-100']},[_c('span',[_vm._v("Sort")]),_c('svg',{staticClass:"h-3 w-3 flex-shrink-0 ml-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 9l-7 7-7-7"}})])])]}},{key:"dropdown",fn:function(){return [_c('ais-sort-by',{staticClass:"p-2",attrs:{"items":_vm.sortByItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var currentRefinement = ref.currentRefinement;
var refine = ref.refine;
return [_c('ul',{staticClass:"mt-1 bg-white w-64 p-2 shadow-lg text-left rounded-lg border border-gray-300 space-y-2 flex flex-col"},_vm._l((items),function(item){return _c('li',{key:item.value},[_c('button',{class:['inline-flex w-full hover:bg-gray-100 py-2 px-4 rounded-lg focus:outline-none', currentRefinement === item.value ? 'bg-gray-200 hover:bg-gray-200' : 'bg-white'],attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.refineAndClose(item.value, refine)}}},[(item.value === 'candidates_source_salary_desc')?_c('AtoZ',{staticClass:"h-6 w-6 mr-2 text-gray-600"}):_vm._e(),(item.value === 'candidates_source_salary_asc')?_c('ZtoA',{staticClass:"h-6 w-6 mr-2 text-gray-600"}):_vm._e(),_c('span',[_vm._v(_vm._s(item.label))])],1)])}),0)]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }