var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col flex-grow"},[_c('div',{staticClass:"flex items-center justify-between flex-shrink-0 border-b border-gray-300 pb-4"},[_c('div',{staticClass:"flex items-center"},[_c('svg',{staticClass:"'h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"}})]),_c('span',{staticClass:"text-xl font-medium ml-2"},[_vm._v("Filters")])]),_c('ais-clear-refinements',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var canRefine = ref.canRefine;
var refine = ref.refine;
var createURL = ref.createURL;
return [(canRefine)?_c('a',{staticClass:"flex items-center text-sm text-gray-600 hover:text-gray-500 ease-out duration-150",attrs:{"href":createURL()},on:{"click":function($event){$event.preventDefault();return refine($event)}}},[_c('svg',{staticClass:"h-3 w-3",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z","clip-rule":"evenodd"}})]),_c('span',{staticClass:"ml-1"},[_vm._v("Clear")])]):_c('span')]}}])})],1),_c('div',{staticClass:"mt-5 flex-1 flex flex-col "},[_c('ul',{staticClass:"flex-1 space-y-8"},[_c('li',{staticClass:"border-b border-gray-300 pb-8"},[_c('h3',{staticClass:"font-medium text-lg mb-10"},[_vm._v(" Salary ")]),_c('ais-range-input',{staticClass:"ml-3",attrs:{"attribute":"normalisedSalary","max":100000},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentRefinement = ref.currentRefinement;
var range = ref.range;
var refine = ref.refine;
var canRefine = ref.canRefine;
return _c('div',{},[_c('vue-slider',{attrs:{"min":10000,"max":100000,"value":_vm.toValue(currentRefinement, range),"disabled":!canRefine,"lazy":true,"use-keyboard":true,"enable-cross":false,"tooltip":"always","duration":0,"interval":5000},on:{"change":function($event){return refine({ min: $event[0], max: $event[1] })}},scopedSlots:_vm._u([{key:"dot",fn:function(ref){
var index = ref.index;
var value = ref.value;
return [_c('div',{staticClass:"vue-slider-dot-handle",attrs:{"aria-valuemin":range.min,"aria-valuemax":range.max,"aria-valuenow":value,"data-handle-key":index,"role":"slider","tabindex":"0"}})]}},{key:"tooltip",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.salaryFormatter(value))+" ")]}}],null,true)})],1)}}])})],1),_c('li',{staticClass:"border-b border-gray-300 pb-8"},[_c('h3',{staticClass:"font-medium text-lg"},[_vm._v(" Skills ")]),_c('ais-refinement-list',{staticClass:"text-sm",attrs:{"attribute":"skills","show-more":"","searchable":"","sort-by":['count:desc'],"limit":10},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var items = ref.items;
            var isShowingMore = ref.isShowingMore;
            var isFromSearch = ref.isFromSearch;
            var canToggleShowMore = ref.canToggleShowMore;
            var refine = ref.refine;
            var searchForItems = ref.searchForItems;
            var toggleShowMore = ref.toggleShowMore;
return [_c('div',{staticClass:"relative my-3"},[_c('div',{staticClass:"absolute inset-y-0 left-2 flex items-center pointer-events-none"},[_c('svg',{staticClass:"h-5 w-5 text-gray-400",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z","clip-rule":"evenodd"}})])]),_c('input',{staticClass:"form-input rounded text-sm w-full h-full pl-8",attrs:{"type":"search","placeholder":"Search skills"},on:{"input":function($event){return searchForItems($event.currentTarget.value)}}})]),_c('ul',{staticClass:"space-y-2 mt-2"},[(!items.length)?_c('li',[_vm._v(" No skills found ")]):_vm._e(),_vm._l((items),function(item){return _c('li',{key:item.value},[_c('span',{staticClass:"flex"},[_c('label',{staticClass:"flex items-center"},[_c('input',{staticClass:"form-checkbox rounded",attrs:{"type":"checkbox"},domProps:{"checked":item.isRefined},on:{"change":function($event){$event.preventDefault();return refine(item.value)}}}),_c('span',{staticClass:"ml-2 text-gray-800 text-sm"},[_c('ais-highlight',{attrs:{"class-names":{
                          'ais-Highlight-highlighted': ['bg-gray-300'],
                        },"attribute":"item","hit":item}}),_vm._v(" ("+_vm._s(item.count.toLocaleString())+") ")],1)])])])})],2),_c('button',{staticClass:"mt-4 underline",attrs:{"disabled":!canToggleShowMore},on:{"click":toggleShowMore}},[_vm._v(" "+_vm._s(!isShowingMore ? 'Show more' : 'Show less')+" ")])]}}])})],1),_c('li',{staticClass:"border-b border-gray-300 pb-8"},[_c('h3',{staticClass:"font-medium text-lg"},[_vm._v(" Role Experience ")]),_c('ais-refinement-list',{staticClass:"text-sm",attrs:{"attribute":"roleExperience.name","searchable":"","show-more":"","sort-by":['count:desc']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var items = ref.items;
            var isShowingMore = ref.isShowingMore;
            var isFromSearch = ref.isFromSearch;
            var canToggleShowMore = ref.canToggleShowMore;
            var refine = ref.refine;
            var createURL = ref.createURL;
            var toggleShowMore = ref.toggleShowMore;
            var searchForItems = ref.searchForItems;
return [_c('div',{staticClass:"relative my-3"},[_c('div',{staticClass:"absolute inset-y-0 left-2 flex items-center pointer-events-none"},[_c('svg',{staticClass:"h-5 w-5 text-gray-400",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z","clip-rule":"evenodd"}})])]),_c('input',{staticClass:"form-input rounded text-sm w-full h-full pl-8",attrs:{"type":"search","placeholder":"Search roles"},on:{"input":function($event){return searchForItems($event.currentTarget.value)}}})]),_c('ul',{staticClass:"space-y-2 mt-2"},[(!items.length)?_c('li',[_vm._v(" No roles found ")]):_vm._e(),_vm._l((items),function(item){return _c('li',{key:item.value},[_c('span',{staticClass:"flex"},[_c('label',{staticClass:"flex items-center"},[_c('input',{staticClass:"form-checkbox rounded",attrs:{"type":"checkbox"},domProps:{"checked":item.isRefined},on:{"change":function($event){$event.preventDefault();return refine(item.value)}}}),_c('span',{staticClass:"ml-2 text-gray-800 text-sm"},[_c('ais-highlight',{attrs:{"class-names":{
                          'ais-Highlight-highlighted': ['bg-gray-300'],
                        },"attribute":"item","hit":item}}),_vm._v(" ("+_vm._s(item.count.toLocaleString())+") ")],1)])])])})],2),_c('button',{staticClass:"mt-4 underline",attrs:{"disabled":!canToggleShowMore},on:{"click":toggleShowMore}},[_vm._v(" "+_vm._s(!isShowingMore ? 'Show more' : 'Show less')+" ")])]}}])})],1),_c('li',{staticClass:"border-b border-gray-300 pb-8"},[_c('h3',{staticClass:"font-medium text-lg mb-2"},[_vm._v(" Qualifications ")]),_c('h4',{staticClass:"text-gray-600 mb-2"},[_vm._v(" Grade ")]),_c('ais-refinement-list',{staticClass:"text-sm",attrs:{"attribute":"educationHistory.grade","show-more":"","sort-by":['count:desc']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var items = ref.items;
            var isFromSearch = ref.isFromSearch;
            var refine = ref.refine;
return [_c('ul',{staticClass:"space-y-2"},[(!items.length)?_c('li',[_vm._v(" No filters ")]):_vm._e(),_vm._l((items),function(item){return _c('li',{key:item.value},[_c('span',{staticClass:"flex"},[_c('label',{staticClass:"flex items-center"},[_c('input',{staticClass:"form-checkbox rounded",attrs:{"type":"checkbox"},domProps:{"checked":item.isRefined},on:{"change":function($event){$event.preventDefault();return refine(item.value)}}}),_c('span',{staticClass:"ml-2 text-gray-800 text-sm"},[_c('ais-highlight',{attrs:{"class-names":{
                          'ais-Highlight-highlighted': ['bg-gray-300'],
                        },"attribute":"item","hit":item}}),_vm._v(" ("+_vm._s(item.count.toLocaleString())+") ")],1)])])])})],2)]}}])}),_c('h4',{staticClass:"text-gray-600 mt-6 mb-2"},[_vm._v(" Classification ")]),_c('ais-refinement-list',{staticClass:"text-sm",attrs:{"attribute":"educationHistory.type","show-more":"","sort-by":['count:desc']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var items = ref.items;
            var isFromSearch = ref.isFromSearch;
            var refine = ref.refine;
return [_c('ul',{staticClass:"space-y-2"},[(!items.length)?_c('li',[_vm._v(" No filters ")]):_vm._e(),_vm._l((items),function(item){return _c('li',{key:item.value},[_c('span',{staticClass:"flex"},[_c('label',{staticClass:"flex items-center"},[_c('input',{staticClass:"form-checkbox rounded",attrs:{"type":"checkbox"},domProps:{"checked":item.isRefined},on:{"change":function($event){$event.preventDefault();return refine(item.value)}}}),_c('span',{staticClass:"ml-2 text-gray-800 text-sm"},[_c('ais-highlight',{attrs:{"class-names":{
                          'ais-Highlight-highlighted': ['bg-gray-300'],
                        },"attribute":"item","hit":item}}),_vm._v(" ("+_vm._s(item.count.toLocaleString())+") ")],1)])])])})],2)]}}])})],1),_c('li',{staticClass:"border-b border-gray-300 pb-8"},[_c('h3',{staticClass:"font-medium text-lg mb-2"},[_vm._v(" Location ")]),_c('ais-refinement-list',{staticClass:"text-sm",attrs:{"attribute":"location.formattedAddress","show-more":"","sort-by":['count:desc']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var items = ref.items;
            var isShowingMore = ref.isShowingMore;
            var isFromSearch = ref.isFromSearch;
            var canToggleShowMore = ref.canToggleShowMore;
            var refine = ref.refine;
            var createURL = ref.createURL;
            var toggleShowMore = ref.toggleShowMore;
return [_c('ul',{staticClass:"space-y-2"},[(!items.length)?_c('li',[_vm._v(" No locations ")]):_vm._e(),_vm._l((items),function(item){return _c('li',{key:item.value},[_c('span',{staticClass:"flex"},[_c('label',{staticClass:"flex items-center"},[_c('input',{staticClass:"form-checkbox rounded",attrs:{"type":"checkbox"},on:{"change":function($event){$event.preventDefault();return refine(item.value)}}}),_c('span',{staticClass:"ml-2 text-gray-800 text-sm"},[_c('ais-highlight',{attrs:{"class-names":{
                          'ais-Highlight-highlighted': ['bg-gray-300'],
                        },"attribute":"item","hit":item}}),_vm._v(" ("+_vm._s(item.count.toLocaleString())+") ")],1)])])])})],2),_c('button',{staticClass:"mt-4 underline",attrs:{"disabled":!canToggleShowMore},on:{"click":toggleShowMore}},[_vm._v(" "+_vm._s(!isShowingMore ? 'Show more' : 'Show less')+" ")])]}}])})],1),_c('li',{staticClass:"border-b border-gray-300 pb-8"},[_c('h3',{staticClass:"font-medium text-lg mb-2"},[_vm._v(" Company Preferences ")]),_c('h4',{staticClass:"text-gray-600 mb-2"},[_vm._v(" Culture ")]),_c('ais-refinement-list',{staticClass:"text-sm",attrs:{"attribute":"companyPreference.culture","show-more":"","sort-by":['count:desc']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var items = ref.items;
            var isFromSearch = ref.isFromSearch;
            var refine = ref.refine;
return [_c('ul',{staticClass:"space-y-2"},[(!items.length)?_c('li',[_vm._v(" No filters ")]):_vm._e(),_vm._l((items),function(item){return _c('li',{key:item.value},[_c('span',{staticClass:"flex"},[_c('label',{staticClass:"flex items-center"},[_c('input',{staticClass:"form-checkbox rounded",attrs:{"type":"checkbox"},domProps:{"checked":item.isRefined},on:{"change":function($event){$event.preventDefault();return refine(item.value)}}}),_c('span',{staticClass:"ml-2 text-gray-800 text-sm"},[_c('ais-highlight',{attrs:{"class-names":{
                          'ais-Highlight-highlighted': ['bg-gray-300'],
                        },"attribute":"item","hit":item}}),_vm._v(" ("+_vm._s(item.count.toLocaleString())+") ")],1)])])])})],2)]}}])}),_c('h4',{staticClass:"text-gray-600 mt-6 mb-2"},[_vm._v(" Size ")]),_c('ais-refinement-list',{staticClass:"text-sm",attrs:{"attribute":"companyPreference.size","show-more":"","sort-by":['count:desc']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var items = ref.items;
            var isFromSearch = ref.isFromSearch;
            var refine = ref.refine;
return [_c('ul',{staticClass:"space-y-2"},[(!items.length)?_c('li',[_vm._v(" No filters ")]):_vm._e(),_vm._l((items),function(item){return _c('li',{key:item.value},[_c('span',{staticClass:"flex"},[_c('label',{staticClass:"flex items-center"},[_c('input',{staticClass:"form-checkbox rounded",attrs:{"type":"checkbox"},domProps:{"checked":item.isRefined},on:{"change":function($event){$event.preventDefault();return refine(item.value)}}}),_c('span',{staticClass:"ml-2 text-gray-800 text-sm"},[_c('ais-highlight',{attrs:{"class-names":{
                          'ais-Highlight-highlighted': ['bg-gray-300'],
                        },"attribute":"item","hit":item}}),_vm._v(" ("+_vm._s(item.count.toLocaleString())+") ")],1)])])])})],2)]}}])})],1),_c('li',{staticClass:"border-b border-gray-300 pb-8"},[_c('h3',{staticClass:"font-medium text-lg mb-2"},[_vm._v(" Employment Preferences ")]),_c('ais-refinement-list',{staticClass:"text-sm",attrs:{"attribute":"employmentPreferences","transform-items":_vm.transformEmploymentPreferences,"sort-by":['count:desc']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var items = ref.items;
            var isFromSearch = ref.isFromSearch;
            var refine = ref.refine;
return [_c('ul',{staticClass:"space-y-2"},[(!items.length)?_c('li',[_vm._v(" No filters ")]):_vm._e(),_vm._l((items),function(item){return _c('li',{key:item.value},[_c('span',{staticClass:"flex"},[_c('label',{staticClass:"flex items-center"},[_c('input',{staticClass:"form-checkbox rounded",attrs:{"type":"checkbox"},domProps:{"checked":item.isRefined},on:{"change":function($event){$event.preventDefault();return refine(item.value)}}}),_c('span',{staticClass:"ml-2 text-gray-800 text-sm"},[_vm._v(" "+_vm._s(item.label)+" ("+_vm._s(item.count.toLocaleString())+") ")])])])])})],2)]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }