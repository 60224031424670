<template>
  <li
    :class="['col-span-1 flex flex-col bg-white rounded-lg shadow divide-y divide-gray-200 transform hover:scale-95 duration-150 ease-in-out relative', { 'shadow-highlight': isPicked }]"
    @mouseover="showViewIcon = true"
    @mouseleave="showViewIcon = false"
  >
    <transition
      enter-active-class="transition-all duration-500 ease-out"
      leave-active-class="transition-all duration-150 ease-in"
      enter-class="opacity-0"
      leave-class="opacity-100"
      enter-to-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="showViewIcon"
        class="absolute right-0 right-0 p-4 cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
          />
        </svg>
      </div>
    </transition>
    <div
      class="flex-1 flex flex-col p-2 py-8 text-center cursor-pointer"
      @click="$emit('view', candidate.objectID)"
    >
      <Photo
        :photo="candidate.image_url"
        size="w-32 h-32"
      />

      <h3 class="mt-2 text-gray-900 font-medium">
        <CandidateName>
          {{ candidate.name }}
        </CandidateName>
      </h3>
      <dl class="mt-1 flex-grow flex flex-col justify-between space-y-2">
        <dt
          v-if="candidate.latestWorkEntry"
          class="sr-only"
        >
          Latest role
        </dt>
        <dd
          v-if="candidate.latestWorkEntry"
          class="text-sm"
        >
          {{ candidate.latestWorkEntry.title }} <span class="block"><span class="text-gray-500 ">at</span> {{ candidate.latestWorkEntry.employer }}</span>
        </dd>
        <dt class="sr-only">
          Location
        </dt>
        <dd
          v-if="candidate.location"
          class="text-sm"
        >
          <span class="text-gray-500">Lives in</span> {{ candidate.location.formattedAddress }}
        </dd>
        <dt class="sr-only">
          Salary
        </dt>
        <dd class="text-sm">
          <span
            v-if="candidate.employment.permanent.preference"
          >£ {{ candidate.employment.permanent.minPay }} <span class="text-gray-500 text-xs">/year</span> </span> <span
            v-if="candidate.employment.contract.preference"
          ><span
            v-if="candidate.employment.permanent.preference && candidate.employment.contract.preference"
            class="text-gray-500"
          >|</span> £ {{ candidate.employment.contract.minPay }} <span class="text-gray-500 text-xs">/day</span></span>
        </dd>
      </dl>
    </div>
    <div>
      <div class="-mt-px flex divide-x divide-gray-200">
        <div class="w-0 flex-1 flex">
          <a
            href="javascript:;"
            :class="['relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500', { 'pointer-events-none opacity-25': isPicked }]"
            @click="$emit('pick', candidate.objectID)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="ml-3">Pick</span>
          </a>
        </div>
        <div class="-ml-px w-0 flex-1 flex">
          <a
            href="javascript:;"
            :class="['relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500', { 'pointer-events-none opacity-25': !isPicked }]"
            @click="$emit('remove', candidate.objectID)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z" />
            </svg>
            <span class="ml-3">Remove</span>
          </a>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import Photo from '@components/CandidateWizardProfile/Photo'
import CandidateName from '@components/Candidates/CandidateName'

export default {
  components: {
    Photo,
    CandidateName
  },

  props: {
    candidate: {
      type: Object,
      default: null
    },
    bulkActionsList: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      showViewIcon: false
    }
  },

  computed: {
    isPicked() {
      return this.bulkActionsList.includes(this.candidate.objectID)
    }
  }
}
</script>
