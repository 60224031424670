<template>
  <Source />
</template>

<script>
import Source from '@components/Source/Source'

export default {
  components: {
    Source
  }
}
</script>
