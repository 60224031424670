<template>
  <div class="w-full max-w-screen-xl mx-auto px-6 mt-8">
    <ais-instant-search
      index-name="candidates_source_salary_desc"
      :search-client="searchClient"
      :routing="routing"
    >
      <div
        class="flex bg-gray-100"
      >
        <div
          v-show="sidebarOpen"
          class="fixed inset-0 flex z-40 md:hidden pt-16"
          role="dialog"
          aria-modal="true"
        >
          <transition
            enter-active-class="transition-opacity ease-linear duration-300"
            leave-active-class="transition-opacity ease-linear duration-300"
            enter-class="opacity-0"
            leave-class="opacity-100"
            enter-to-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div
              v-show="sidebarOpen"
              class="fixed inset-0 bg-gray-600 bg-opacity-75"
              aria-hidden="true"
              @click="sidebarOpen = false"
            ></div>
          </transition>

          <transition
            enter-active-class="transition ease-in-out duration-300 transform"
            leave-active-class="transition ease-in-out duration-300 transform"
            enter-class="-translate-x-full"
            leave-class="translate-x-0"
            enter-to-class="translate-x-0"
            leave-to-class="-translate-x-full"
          >
            <div
              v-show="sidebarOpen"
              class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white shadow px-6"
            >
              <transition
                enter-active-class="ease-in-out duration-300"
                leave-active-class="ease-in-out duration-300"
                enter-class="opacity-0"
                leave-class="opacity-100"
                enter-to-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <!-- Heroicon name: outline/x -->
                    <svg
                      class="h-6 w-6 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </transition>
              <div class="flex items-center flex-shrink-0 border-b border-gray-300 pb-4">
                <Filters />
              </div>
            </div>
          </transition>

          <div
            class="flex-shrink-0 w-14"
            aria-hidden="true"
          >
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>

        <!-- Static sidebar for desktop -->
        <div class="hidden bg-white md:flex md:flex-shrink-0 rounded">
          <div class="flex flex-col w-72">
            <Filters class="pt-5 pb-16 px-6 shadow" />
          </div>
        </div>
        <div class="flex flex-col w-0 flex-1 overflow-hidden">
          <div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow rounded md:ml-6">
            <button
              type="button"
              class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              @click="sidebarOpen = true"
            >
              <span class="sr-only">Open sidebar</span>
              <!-- Heroicon name: outline/menu-alt-2 -->
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </button>
            <div class="flex-1 px-4 flex justify-between">
              <div class="flex-1 flex">
                <form
                  class="w-full flex md:ml-0"
                  action="#"
                  method="GET"
                >
                  <label
                    for="search-field"
                    class="sr-only"
                  >
                    Search
                  </label>
                  <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                      <!-- Heroicon name: solid/search -->
                      <svg
                        class="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <ais-search-box class="w-full h-full">
                      <template v-slot="{ currentRefinement, isSearchStalled, refine }">
                        <input
                          id="search-field"
                          type="search"
                          class="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent"
                          name="search"
                          placeholder="Search name, location, roles..."
                          :value="currentRefinement"
                          @input="refine($event.currentTarget.value)"
                        >
                        <span :hidden="!isSearchStalled">Loading...</span>
                      </template>
                    </ais-search-box>
                  </div>
                </form>
              </div>
              <div class="ml-4 flex items-center md:ml-6">
                <button
                  v-if="bulkActionsList.length"
                  class="text-xs mr-4 text-secondary-400"
                  @click="bulkActionsList = []"
                >
                  x Clear Picks
                </button>
                <ActionsButton
                  v-if="bulkActionsList.length"
                  class="mr-4"
                />
                <SortCandidatesButton class="mr-4"/>
                <ais-stats>
                  <template v-slot="{ nbHits }">
                    <p
                      class="text-sm text-gray-600"
                    >
                      {{ nbHits }} candidates found
                    </p>
                  </template>
                </ais-stats>
              </div>
            </div>
          </div>

          <main class="flex-1 relative focus:outline-none">
            <div class="py-6 px-6">
              <div class="flex flex-wrap items-center pb-6">
                <ais-current-refinements class="flex">
                  <template v-slot="{ items, createURL }">
                    <ul class="flex flex-wrap items-center text-xs">
                      <li
                        v-for="item in items"
                        :key="item.attribute"
                        class="flex flex-wrap items-center"
                      >
                        <ul class="flex flex-wrap items-center">
                          <li
                            v-for="refinement in item.refinements"
                            :key="[
                              refinement.attribute,
                              refinement.type,
                              refinement.operator,
                              refinement.value,
                            ].join(':')"
                            class="px-2 py-1"
                          >
                            <a
                              :href="createURL(refinement)"
                              class="inline-flex items-center px-3 py-0.5 rounded-lg text-sm bg-gray-300 text-gray-700 hover:bg-gray-400 focus:outline-none duration-150 ease-out"
                              @click.prevent="item.refine(refinement)"
                            >
                              <p v-if="item.label === 'normalisedSalary'">
                                <strong class="mr-1">{{ fieldLabelMap(item.label) }}:</strong> {{ operatorMap(refinement.operator) }} {{ salaryFormatter(refinement.value) }}
                              </p>

                              <p
                                v-else
                                class="mr-1"
                              >
                                <strong class="mr-1">{{ fieldLabelMap(item.label) }}:</strong> {{ titleCase(refinement.value) }}
                              </p>

                              <span
                                class="ml-1"
                              >
                                <svg
                                  class="h-2 w-2 transform"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 8 8"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-width="1.5"
                                    d="M1 1l6 6m0-6L1 7"
                                  />
                                </svg>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </template>
                </ais-current-refinements>
              </div>
              <div class="max-w-7xl mx-auto">
                <ais-infinite-hits :cache="cachedResults">
                  <template
                    v-slot="{
                      items,
                      refineNext,
                      isLastPage,
                    }"
                  >
                    <p
                      v-if="!items.length"
                      class="text-sm text-gray-600"
                    >
                      No candidates found
                    </p>
                    <ul
                      role="list"
                      class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
                    >
                      <CandidateCard
                        v-for="item in items"
                        :key="item.objectID"
                        :candidate="item"
                        :bulk-actions-list="bulkActionsList"
                        @view="viewCandidate($event)"
                        @pick="addToBulkActionsList($event)"
                        @remove="removeFromBulkActionsList($event)"
                      />
                    </ul>

                    <div class="w-full flex justify-center pt-16 pb-10">
                      <BaseButton
                        v-if="!isLastPage"
                        variant="ghost"
                        class="mx-auto text-center"
                        @click="refineNext"
                      >
                        Show more
                      </BaseButton>
                    </div>
                  </template>
                </ais-infinite-hits>
                <!-- <div class="py-4">
                  <div class="border-4 border-dashed border-gray-200 rounded-lg h-96"></div>
                </div> -->
              </div>
            </div>
          </main>
        </div>
      </div>
    </ais-instant-search>
  </div>
</template>

<script>
import SortCandidatesButton from '@components/Source/SortCandidatesButton'
import CandidateCard from '@components/Source/CandidateCard'
import ActionsButton from '@components/Source/ActionsButton'
import Filters from '@components/Source/Filters'
import { titleCase } from '@utils/titleCase'

import { searchClient } from '@utils/algoliaClient'
import { history as historyRouter } from 'instantsearch.js/es/lib/routers'
import { singleIndex as singleIndexMapping } from 'instantsearch.js/es/lib/stateMappings'
import {
  createInfiniteHitsSessionStorageCache
} from 'instantsearch.js/es/lib/infiniteHitsCache'

export default {
  components: {
    SortCandidatesButton,
    CandidateCard,
    ActionsButton,
    Filters
  },

  data() {
    return {
      searchClient,
      routing: {
        router: historyRouter(),
        stateMapping: singleIndexMapping('candidates_source_salary_desc')
      },
      cachedResults: createInfiniteHitsSessionStorageCache(),
      sidebarOpen: false,
      bulkActionsList: [],
      savedCandidates: [],
      titleCase
    }
  },

  methods: {
    viewCandidate(candidateId) {
      console.log('View Candidate Profile')
      // this.$router.push({
      //   name: 'candidate-profile-enhanced', params: { id: candidateId }
      // })
    },

    saveCandidate(candidate) {
      this.savedCandidates.push(candidate)
    },

    addToBulkActionsList(candidate) {
      this.bulkActionsList.push(candidate)
    },

    removeFromBulkActionsList(candidate) {
      this.bulkActionsList = this.bulkActionsList.filter(candidateId => candidate !== candidateId)
    },

    fieldLabelMap(label) {
      const fields = {
        'normalisedSalary': 'Salary',
        'skills': 'Skill',
        'location.formattedAddress': 'Location',
        'roleExperience.name': 'Role',
        'companyPreference.culture': 'Company Culture',
        'companyPreference.size': 'Company Size',
        'employmentPreferences': 'Employment Status',
        'educationHistory.grade': 'Grade',
        'educationHistory.type': 'Classification'
      }
      return fields[label]
    },

    operatorMap(operator) {
      const operators = {
        '>=': 'Greater than',
        '<=': 'Less than'
      }
      return operators[operator]
    },

    salaryFormatter(num) {
      return `£${num > 999 ? num % 1000 === 0 ? (num / 1000).toFixed(0) + 'k' : (num / 1000).toFixed(1) + 'k' : num}`
    }
  }
}
</script>
