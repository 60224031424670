<template>
  <div class="flex flex-col flex-grow">
    <div class="flex items-center justify-between flex-shrink-0 border-b border-gray-300 pb-4">
      <div class="flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="'h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
          />
        </svg>
        <span class="text-xl font-medium ml-2">Filters</span>
      </div>

      <ais-clear-refinements>
        <template v-slot="{ canRefine, refine, createURL }">
          <a
            v-if="canRefine"
            :href="createURL()"
            class="flex items-center text-sm text-gray-600 hover:text-gray-500 ease-out duration-150"
            @click.prevent="refine"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-3 w-3"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="ml-1">Clear</span>
          </a>
          <span v-else></span>
        </template>
      </ais-clear-refinements>
    </div>
    <div class="mt-5 flex-1 flex flex-col ">
      <ul class="flex-1 space-y-8">
        <li class="border-b border-gray-300 pb-8">
          <h3 class="font-medium text-lg mb-10">
            Salary
          </h3>
          <ais-range-input
            attribute="normalisedSalary"
            class="ml-3"
            :max="100000"
          >
            <div slot-scope="{ currentRefinement, range, refine, canRefine }">
              <vue-slider
                :min="10000"
                :max="100000"
                :value="toValue(currentRefinement, range)"
                :disabled="!canRefine"
                :lazy="true"
                :use-keyboard="true"
                :enable-cross="false"
                tooltip="always"
                :duration="0"
                :interval="5000"
                @change="refine({ min: $event[0], max: $event[1] })"
              >
                <template
                  slot="dot"
                  slot-scope="{ index, value }"
                >
                  <div
                    :aria-valuemin="range.min"
                    :aria-valuemax="range.max"
                    :aria-valuenow="value"
                    :data-handle-key="index"
                    class="vue-slider-dot-handle"
                    role="slider"
                    tabindex="0"
                  />
                </template>
                <template
                  slot="tooltip"
                  slot-scope="{ value }"
                >
                  {{ salaryFormatter(value) }}
                </template>
              </vue-slider>
            </div>
          </ais-range-input>
        </li>

        <li class="border-b border-gray-300 pb-8">
          <h3 class="font-medium text-lg">
            Skills
          </h3>

          <ais-refinement-list
            attribute="skills"
            show-more
            searchable
            class="text-sm"
            :sort-by="['count:desc']"
            :limit="10"
          >
            <template
              v-slot="{
                items,
                isShowingMore,
                isFromSearch,
                canToggleShowMore,
                refine,
                searchForItems,
                toggleShowMore
              }"
            >
              <div class="relative my-3">
                <div class="absolute inset-y-0 left-2 flex items-center pointer-events-none">
                  <svg
                    class="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>

                <input
                  type="search"
                  class="form-input rounded text-sm w-full h-full pl-8"
                  placeholder="Search skills"
                  @input="searchForItems($event.currentTarget.value)"
                />
              </div>

              <ul class="space-y-2 mt-2">
                <li v-if="!items.length">
                  No skills found
                </li>
                <li
                  v-for="item in items"
                  :key="item.value"
                >
                  <span class="flex">
                    <label class="flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox rounded"
                        :checked="item.isRefined"
                        @change.prevent="refine(item.value)"
                      >
                      <span class="ml-2 text-gray-800 text-sm">
                        <ais-highlight
                          :class-names="{
                            'ais-Highlight-highlighted': ['bg-gray-300'],
                          }"
                          attribute="item"
                          :hit="item"
                        />
                        ({{ item.count.toLocaleString() }})
                      </span>
                    </label>
                  </span>
                </li>
              </ul>

              <button
                class="mt-4 underline"
                :disabled="!canToggleShowMore"
                @click="toggleShowMore"
              >
                {{ !isShowingMore ? 'Show more' : 'Show less' }}
              </button>
            </template>
          </ais-refinement-list>
        </li>

        <li class="border-b border-gray-300 pb-8">
          <h3 class="font-medium text-lg">
            Role Experience
          </h3>

          <ais-refinement-list
            attribute="roleExperience.name"
            searchable
            show-more
            class="text-sm"
            :sort-by="['count:desc']"
          >
            <template
              v-slot="{
                items,
                isShowingMore,
                isFromSearch,
                canToggleShowMore,
                refine,
                createURL,
                toggleShowMore,
                searchForItems
              }"
            >
              <div class="relative my-3">
                <div class="absolute inset-y-0 left-2 flex items-center pointer-events-none">
                  <svg
                    class="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>

                <input
                  type="search"
                  class="form-input rounded text-sm w-full h-full pl-8"
                  placeholder="Search roles"
                  @input="searchForItems($event.currentTarget.value)"
                />
              </div>

              <ul class="space-y-2 mt-2">
                <li v-if="!items.length">
                  No roles found
                </li>
                <li
                  v-for="item in items"
                  :key="item.value"
                >
                  <span class="flex">
                    <label class="flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox rounded"
                        :checked="item.isRefined"
                        @change.prevent="refine(item.value)"
                      >
                      <span class="ml-2 text-gray-800 text-sm">
                        <ais-highlight
                          :class-names="{
                            'ais-Highlight-highlighted': ['bg-gray-300'],
                          }"
                          attribute="item"
                          :hit="item"
                        />
                        ({{ item.count.toLocaleString() }})
                      </span>
                    </label>
                  </span>
                </li>
              </ul>

              <button
                class="mt-4 underline"
                :disabled="!canToggleShowMore"
                @click="toggleShowMore"
              >
                {{ !isShowingMore ? 'Show more' : 'Show less' }}
              </button>
            </template>
          </ais-refinement-list>
        </li>

        <li class="border-b border-gray-300 pb-8">
          <h3 class="font-medium text-lg mb-2">
            Qualifications
          </h3>
          <h4 class="text-gray-600 mb-2">
            Grade
          </h4>
          <ais-refinement-list
            attribute="educationHistory.grade"
            show-more
            class="text-sm"
            :sort-by="['count:desc']"
          >
            <template
              v-slot="{
                items,
                isFromSearch,
                refine
              }"
            >
              <ul class="space-y-2">
                <li v-if="!items.length">
                  No filters
                </li>
                <li
                  v-for="item in items"
                  :key="item.value"
                >
                  <span class="flex">
                    <label class="flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox rounded"
                        :checked="item.isRefined"
                        @change.prevent="refine(item.value)"
                      >
                      <span class="ml-2 text-gray-800 text-sm">
                        <ais-highlight
                          :class-names="{
                            'ais-Highlight-highlighted': ['bg-gray-300'],
                          }"
                          attribute="item"
                          :hit="item"
                        />
                        ({{ item.count.toLocaleString() }})
                      </span>
                    </label>
                  </span>
                </li>
              </ul>
            </template>
          </ais-refinement-list>

          <h4 class="text-gray-600 mt-6 mb-2">
            Classification
          </h4>
          <ais-refinement-list
            attribute="educationHistory.type"
            show-more
            class="text-sm"
            :sort-by="['count:desc']"
          >
            <template
              v-slot="{
                items,
                isFromSearch,
                refine
              }"
            >
              <ul class="space-y-2">
                <li v-if="!items.length">
                  No filters
                </li>
                <li
                  v-for="item in items"
                  :key="item.value"
                >
                  <span class="flex">
                    <label class="flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox rounded"
                        :checked="item.isRefined"
                        @change.prevent="refine(item.value)"
                      >
                      <span class="ml-2 text-gray-800 text-sm">
                        <ais-highlight
                          :class-names="{
                            'ais-Highlight-highlighted': ['bg-gray-300'],
                          }"
                          attribute="item"
                          :hit="item"
                        />
                        ({{ item.count.toLocaleString() }})
                      </span>
                    </label>
                  </span>
                </li>
              </ul>
            </template>
          </ais-refinement-list>
        </li>

        <li class="border-b border-gray-300 pb-8">
          <h3 class="font-medium text-lg mb-2">
            Location
          </h3>
          <ais-refinement-list
            attribute="location.formattedAddress"
            show-more
            class="text-sm"
            :sort-by="['count:desc']"
          >
            <template
              v-slot="{
                items,
                isShowingMore,
                isFromSearch,
                canToggleShowMore,
                refine,
                createURL,
                toggleShowMore
              }"
            >
              <ul class="space-y-2">
                <li v-if="!items.length">
                  No locations
                </li>
                <li
                  v-for="item in items"
                  :key="item.value"
                >
                  <span class="flex">
                    <label class="flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox rounded"
                        @change.prevent="refine(item.value)"
                      >
                      <span class="ml-2 text-gray-800 text-sm">
                        <ais-highlight
                          :class-names="{
                            'ais-Highlight-highlighted': ['bg-gray-300'],
                          }"
                          attribute="item"
                          :hit="item"
                        />
                        ({{ item.count.toLocaleString() }})
                      </span>
                    </label>
                  </span>
                </li>
              </ul>

              <button
                class="mt-4 underline"
                :disabled="!canToggleShowMore"
                @click="toggleShowMore"
              >
                {{ !isShowingMore ? 'Show more' : 'Show less' }}
              </button>
            </template>
          </ais-refinement-list>
        </li>
        <li class="border-b border-gray-300 pb-8">
          <h3 class="font-medium text-lg mb-2">
            Company Preferences
          </h3>
          <h4 class="text-gray-600 mb-2">
            Culture
          </h4>
          <ais-refinement-list
            attribute="companyPreference.culture"
            show-more
            class="text-sm"
            :sort-by="['count:desc']"
          >
            <template
              v-slot="{
                items,
                isFromSearch,
                refine
              }"
            >
              <ul class="space-y-2">
                <li v-if="!items.length">
                  No filters
                </li>
                <li
                  v-for="item in items"
                  :key="item.value"
                >
                  <span class="flex">
                    <label class="flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox rounded"
                        :checked="item.isRefined"
                        @change.prevent="refine(item.value)"
                      >
                      <span class="ml-2 text-gray-800 text-sm">
                        <ais-highlight
                          :class-names="{
                            'ais-Highlight-highlighted': ['bg-gray-300'],
                          }"
                          attribute="item"
                          :hit="item"
                        />
                        ({{ item.count.toLocaleString() }})
                      </span>
                    </label>
                  </span>
                </li>
              </ul>
            </template>
          </ais-refinement-list>

          <h4 class="text-gray-600 mt-6 mb-2">
            Size
          </h4>
          <ais-refinement-list
            attribute="companyPreference.size"
            show-more
            class="text-sm"
            :sort-by="['count:desc']"
          >
            <template
              v-slot="{
                items,
                isFromSearch,
                refine
              }"
            >
              <ul class="space-y-2">
                <li v-if="!items.length">
                  No filters
                </li>
                <li
                  v-for="item in items"
                  :key="item.value"
                >
                  <span class="flex">
                    <label class="flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox rounded"
                        :checked="item.isRefined"
                        @change.prevent="refine(item.value)"
                      >
                      <span class="ml-2 text-gray-800 text-sm">
                        <ais-highlight
                          :class-names="{
                            'ais-Highlight-highlighted': ['bg-gray-300'],
                          }"
                          attribute="item"
                          :hit="item"
                        />
                        ({{ item.count.toLocaleString() }})
                      </span>
                    </label>
                  </span>
                </li>
              </ul>
            </template>
          </ais-refinement-list>
        </li>
        <li class="border-b border-gray-300 pb-8">
          <h3 class="font-medium text-lg mb-2">
            Employment Preferences
          </h3>
          <ais-refinement-list
            attribute="employmentPreferences"
            class="text-sm"
            :transform-items="transformEmploymentPreferences"
            :sort-by="['count:desc']"
          >
            <template
              v-slot="{
                items,
                isFromSearch,
                refine
              }"
            >
              <ul class="space-y-2">
                <li v-if="!items.length">
                  No filters
                </li>
                <li
                  v-for="item in items"
                  :key="item.value"
                >
                  <span class="flex">
                    <label class="flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox rounded"
                        :checked="item.isRefined"
                        @change.prevent="refine(item.value)"
                      >
                      <span class="ml-2 text-gray-800 text-sm">
                        {{ item.label }}
                        ({{ item.count.toLocaleString() }})
                      </span>
                    </label>
                  </span>
                </li>
              </ul>
            </template>
          </ais-refinement-list>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import { titleCase } from '@utils/titleCase'

export default {
  components: {
    VueSlider
  },

  data() {
    return {
      titleCase
    }
  },

  methods: {
    toValue(value, range) {
      return [
        typeof value.min === 'number' ? value.min : range.min,
        typeof value.max === 'number' ? value.max : range.max
      ]
    },

    transformEmploymentPreferences(items) {
      return items.map(item => ({
        ...item,
        label: this.titleCase(item.label)
      }))
    },

    salaryFormatter(num) {
      return `£${num > 999 ? num % 1000 === 0 ? (num / 1000).toFixed(0) + 'k' : (num / 1000).toFixed(1) + 'k' : num}`
    }
  }
}
</script>
