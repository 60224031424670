<template>
  <Dropdown
    class="hidden sm:block z-50"
    :open="dropdownOpen"
  >
    <template #trigger="{ hasFocus, isOpen }">
      <span
        class="flex items-center text-sm rounded-lg px-3 py-1"
        :class="[isOpen ? 'bg-gray-300' : 'bg-gray-100']"
      >
        <span>Sort</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-3 w-3 flex-shrink-0 ml-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </span>
    </template>
    <template #dropdown>
      <ais-sort-by
        :items="sortByItems"
        class="p-2"
      >
        <template v-slot="{ items, currentRefinement, refine }">
          <ul class="mt-1 bg-white w-64 p-2 shadow-lg text-left rounded-lg border border-gray-300 space-y-2 flex flex-col">
            <li
              v-for="item in items"
              :key="item.value"
            >
              <button
                type="button"
                :class="['inline-flex w-full hover:bg-gray-100 py-2 px-4 rounded-lg focus:outline-none', currentRefinement === item.value ? 'bg-gray-200 hover:bg-gray-200' : 'bg-white']"
                @click.prevent="refineAndClose(item.value, refine)"
              >
                <AtoZ
                  v-if="item.value === 'candidates_source_salary_desc'"
                  class="h-6 w-6 mr-2 text-gray-600"
                />
                <ZtoA
                  v-if="item.value === 'candidates_source_salary_asc'"
                  class="h-6 w-6 mr-2 text-gray-600"
                />
                <span>{{ item.label }}</span>
              </button>
            </li>
          </ul>
        </template>
      </ais-sort-by>
    </template>
  </Dropdown>
</template>

<script>
import Dropdown from '@components/Dropdown'
import AtoZ from '@components/Icons/Isolated/AtoZ'
import ZtoA from '@components/Icons/Isolated/ZtoA'

export default {
  components: {
    Dropdown,
    AtoZ,
    ZtoA
  },

  data() {
    return {
      dropdownOpen: false,
      sortByItems: [
        { value: 'candidates_source_salary_desc', label: 'Salary: High to Low' },
        { value: 'candidates_source_salary_asc', label: 'Salary: Low to High' }
      ]
    }
  },

  methods: {
    refineAndClose(value, refine) {
      refine(value)
      console.log(this.dropdownOpen)
      this.dropdownOpen = !this.dropdownOpen
    }
  }
}
</script>
