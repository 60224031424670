<template>
  <AccessibleDropdown
    :options="options"
    :loading="loading"
    align="right"
    @input="handleClick($event)"
  >
    <template v-slot:button="{ isOpen }">
      <span
        class="text-sm text-white rounded-lg px-3 py-1"
        :class="[isOpen ? 'bg-secondary-400' : 'bg-secondary']"
      >
        <span>Actions</span>
      </span>
    </template>

    <template v-slot:buttonContent="{ option }">
      <component
        :is="option.icon"
        v-if="option.icon"
        class="h-6 w-6 mr-2 text-gray-600"
      />
      <span>{{ option.name }}</span>
    </template>
  </AccessibleDropdown>
</template>

<script>
import AccessibleDropdown from '@components/AccessibleDropdown'

export default {
  components: {
    AccessibleDropdown
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      chosenItem: null,
      options: [
        {
          name: 'Add to job',
          icon: () => import('@components/Icons/Isolated/Arrow')
        },
        {
          name: 'Add to company pool',
          icon: () => import('@components/Icons/Isolated/Arrow')
        },
        {
          name: 'Send assessments',
          icon: () => import('@components/Icons/Isolated/Arrow')
        }
      ]
    }
  },

  methods: {
    handleClick(event) {
      this.chosenItem = event

      const events = {
        'Move To Stage': 'move-to-stage',
        'Disqualify': 'qualification-status-update',
        'Requalify': 'qualification-status-update',
        'Delete': 'delete-candidates'
      }
      this.$emit(events[event.name], event.name)
    }
  }
}
</script>
